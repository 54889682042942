import Highcharts from "highcharts"
import initializeHighchartsMore from "highcharts/highcharts-more"
import { SchoolAllSubjectsPointMetaData } from "components/ambition/all-subjects/chart/chart.helpers"
import { SchoolSubjectPointMetaData } from "components/ambition/subject/chart/chart.helpers"
import { MethodTestResultPointMetaData } from "components/method-test-result-chart/method-test-result-chart.helpers"
import { SchoolYearGradePointMetaData } from "components/ambition/details/charts/yeargrade-chart.helpers"

export const DATA_ATTRIBUTE_HREF = "data-href"
export const DATA_ATTRIBUTE_TOOLTIP_TEXT = "data-tooltip-text"

type MetaData =
  | SchoolAllSubjectsPointMetaData
  | SchoolSubjectPointMetaData
  | SchoolYearGradePointMetaData
  | MethodTestResultPointMetaData

/*
  Extension for Point interface from highcharts library types to include:
  1. SVG element reference (is provided by highcharts, but not present in types)
  2. Chart point metadata that gets added on our side, using a discriminated union of all different metadata types.
    NOTE: It should be narrowed down to a specific type when accessed. Use chartType property to differentiate between various types.
    (see SchoolPageAllSubjectsChart and SchoolAllSubjectsPointMetaData for an example of usage)
*/
declare module "highcharts" {
  export interface Point {
    graphic: {
      element: SVGRectElement
    }
    meta?: MetaData
  }
}

export const initializeHighcharts = () => {
  initializeHighchartsMore(Highcharts)
  Highcharts.AST.allowedAttributes.push(
    DATA_ATTRIBUTE_HREF,
    DATA_ATTRIBUTE_TOOLTIP_TEXT,
  )
}
