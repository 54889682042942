import React from "react"
import PropTypes from "prop-types"
import { withRouter, Route, Switch, Redirect, Router } from "react-router-dom"
import { Helmet } from "react-helmet"
import { Provider, connect } from "react-redux"
import { isNil } from "ramda"
import { LULoader } from "webclient-ui/components"
import { captureException as sentryCaptureException } from "@sentry/browser"

import { history, reduxStore } from "store"
import * as SessionAPI from "lib/core/session.api"
import * as RouterUtils from "lib/core/router.utils"
import * as StorageAPI from "lib/core/storage.api"
import ScrollToTop from "components/scroll-to-top/scroll-to-top"
import UnknownErrorView from "components/error/unknown_error_view"
import ClientErrorView from "components/error/client_error_view"
import {
  clearErrors,
  loadUserProfile,
  getUserSettings,
} from "entities/sessions/sessions.actions"

import { LOGIN_REDIRECT_STORAGE_KEY } from "lib/leeruniek/constants"

import { StyledEngineProvider, ThemeProvider } from "@mui/material"
import LUTheme from "components/ui/themes/lutheme"
import GuestLayout from "entities/guest.layout/guest.layout"
import UserLayout from "entities/user.layout/user.layout"

export class PureAppRoutes extends React.Component {
  constructor(props) {
    super(props)
    this.state = { errorOccurred: false }
  }

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    hasErrors: PropTypes.bool.isRequired,
  }

  /**
   * This function will be called only once in the whole life-cycle of a given
   * component and it being called signalizes that the component and all its
   * sub-components rendered properly.
   *
   * DO
   *  - cause side effects (AJAX calls etc.)
   *
   * DON'T
   *  - call this.setState as it will result in a re-render
   */
  componentDidMount = () => {
    //Add listener to history to enable smooth code autoupdate if new code is available
    this.props.history.listen(() => {
      if (this.props.shouldClientAutoUpdate) {
        window.location.reload()
      }
    })
    if (SessionAPI.hasToken()) {
      // This condition is needed because we can't load a profile
      // for users which don't have one, and access the site through
      // e.g., email invite.
      this.props.xLoadUserProfile()

      this.props.getUserSettings()
    }
  }

  componentDidUpdate = () => {
    const { hasAuthError, xClearErrors } = this.props
    if (hasAuthError) {
      history.push("/auth/login")
      window.location.reload()
      xClearErrors()
    }
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true })

    sentryCaptureException(error, { extra: info })
  }

  handleErrorsClean = () => {
    this.setState({ errorOccurred: false })
    this.props.xClearErrors()
  }

  renderErrorLayout = () => {
    if (this.props.hasAccessError) {
      return (
        <ClientErrorView
          isAccessError={this.props.hasAccessError}
          onErrorsClean={this.handleErrorsClean}
        />
      )
    } else {
      return <UnknownErrorView cancelErrorOccurred={this.handleErrorsClean} />
    }
  }

  /**
   * When called, it should examine this.props and this.state and return a
   * single React element. This element can be either a representation of a
   * native DOM component, such as <div />, or another composite component
   * that you've defined yourself.
   *
   * @return {Component}
   */
  render() {
    /*
     * isLoading is by default true, so the Loader doesnt get to first load the
     * content then spinner and then content again
     */
    const { isLoading, isAuthorized, hasServerError, hasAccessError } =
      this.props
    const { errorOccurred } = this.state

    return (
      <LULoader isLoading={isLoading}>
        <Helmet titleTemplate="%s - Leeruniek" encodeSpecialCharacters={true} />

        <ScrollToTop>
          <ThemeProvider theme={LUTheme}>
            {errorOccurred || hasServerError || hasAccessError ? (
              this.renderErrorLayout()
            ) : isAuthorized ? (
              <Switch>
                <Route path="/app" component={UserLayout} />
                <Redirect exact={true} from="/" to="/app" />
                <Route path="/auth/login" render={null} />
                <Route
                  render={(routerProps) => (
                    <ClientErrorView isNotFound={true} {...routerProps} />
                  )}
                />
              </Switch>
            ) : (
              <Switch>
                <Route path="/auth" component={GuestLayout} />
                <Route exact={true} path="/" render={this.handleRedirect} />
                <Route path="/app" render={this.handleRedirect} />
                <Route
                  render={(routerProps) => (
                    <ClientErrorView isNotFound={true} {...routerProps} />
                  )}
                />
              </Switch>
            )}
          </ThemeProvider>
        </ScrollToTop>
      </LULoader>
    )
  }

  /**
   * If accessing something that the user is not allowed to see, redirect
   * to login.
   *
   * Save current path to session storage before redirecting, we'll send the
   * user back after login is successful.
   *
   * @return {ReactComponent}
   */
  handleRedirect = () => {
    const { location } = this.props

    StorageAPI.upsert(
      {
        key: LOGIN_REDIRECT_STORAGE_KEY,
        value: location.pathname + location.search,
      },
      {
        local: false,
      },
    )

    return <Redirect to={RouterUtils.get("account__login")} />
  }
}
const AppRoutes = withRouter(
  connect(
    (state) => ({
      isLoading: state.session.isUserLoading,
      isAuthorized: state.session.isUserAuthorized,
      user: state.session.user,
      hasErrors: state.session.hasErrors,
      hasServerError: !isNil(state.session.errors.unhandledError),
      hasAuthError: !isNil(state.session.errors.authError),
      hasAccessError: !isNil(state.session.errors.accessError),
      shouldClientAutoUpdate: state.session.shouldClientAutoUpdate,
    }),
    {
      xLoadUserProfile: loadUserProfile,
      xClearErrors: clearErrors,
      getUserSettings,
    },
  )(PureAppRoutes),
)

export default () => (
  <StyledEngineProvider injectFirst>
    <Provider store={reduxStore}>
      <Router history={history}>
        <AppRoutes />
      </Router>
    </Provider>
  </StyledEngineProvider>
)
