import React from "react"
import css from "./multifactor-auth-app.module.scss"
import { t } from "./multifactor-auth-app.i18n"
import MLUTextField from "components/ui/mlutextfield/mlutextfield"

export const CODE_LENGTH = 6

interface MultiFactorAuthAppCodeInputProps {
  code?: string
  onCodeChange: (code: string) => void
  onSubmit: (code: string) => void
  [x: string]: any
}

export const MultiFactorAuthAppCodeInput = ({
  code,
  onCodeChange,
  onSubmit,
  ...props
}: MultiFactorAuthAppCodeInputProps) => {
  const handleCodeChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    onCodeChange(event.currentTarget.value.replace(/\D/, "").substring(0, 6))
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && code?.length === CODE_LENGTH) {
      onSubmit(code)
    }
  }

  return (
    <MLUTextField
      className={css["code-input"]}
      focused
      hasBar={false}
      name="authentication-code"
      onChange={handleCodeChange}
      onKeyPress={handleKeyPress}
      placeholder={t("code-placeholder")}
      value={code}
      {...props}
    />
  )
}
